<template lang="pug">
v-container
  FlashMessage(:position="'left bottom'", :strategy="'multiple'" style="z-index:999")
  v-card.pa-5.overflow-hidden(outlined)
    .covered.ma-n5.pt-4.pb-2
      h5.text-center.white--text Create Text Announcements
    .mt-3
      v-card.pa-7(outlined)
        form(@submit.prevent="submit")
          v-text-field(
            filled, 
            label="Title", 
            v-model="name" 
            required 
          )
          v-textarea(
            name="input-4-1",
            filled,
            label="Description",
            auto-grow,
            v-model="description"
            required 
          )
        .text-end
          v-btn(
            color="success",
            type="submit"
            @click="createAnn()",
            @click.prevent="submit" 
            :disabled="description==='' || name===''"
            :loading="loading"
          ) Add
      h3.mt-7 Text Announcements:
      v-card.pa-5.mt-4(v-for="ann in anns", :key="ann.id", outlined)
        h5 {{ ann.name }}
        p(style="white-space: pre-wrap") {{ ann.description }}
        .text-end
          v-btn(
            color="error",
            @click="deleteAnn(ann.id); index = ann.id",
            :loading="loading1 && ann.id == index",
            small
          ) Delete
  br
  v-card.pa-5(outlined)
    .covered.ma-n5.pt-4.pb-2
      h5.text-center.white--text Create Image Announcements
    .mt-3
      v-card.pa-7.text-center(outlined)
        span
          v-btn(color="primary", @click="image = true") Upload Image
          v-dialog(v-model="image", width="600")
            v-card.text-center.pa-5.mr-n5.text-center(height="230")
              h4 Upload image
              br
              input(
                type="file",
                @change="previewImage",
                accept="image/*"
              )
              br
              br
              v-btn.mr-5(text, @click="image = false", small) Cancel
              v-btn(
                @click="onUpload(); image = false",
                v-if="imageData != null",
                color="success",
                small
                :loading="loading4"
              ) Upload
              v-btn(disabled, small, v-else) Upload
        .text-center.mt-5(v-if="uploadValue == 100")
          img(
            :src="picture",
            style="width: 50%; height: 200px; object-fit: cover"
          )
        v-card.mx-auto.text-center.py-7.mt-5(
          v-else,
          width="50%",
          height="285px",
          outlined
        )
          v-icon.text-muted mdi-file-image
          h6.font-weight-normal.text-muted.px-3.text-justify To have a uniform carousel dimension and a good carousel-to-content ratio, it is highly encouraged to upload an image with a dimension of: <strong>1440px by 510px</strong>. You may use <strong><a href="https://www.befunky.com/create/crop-photo/"> BeFunky Photo Editor</a></strong> to crop or resize your photo.
          v-progress-circular#progress.mt-4(
            :size="100",
            :width="15",
            :value="uploadValue",
            color="primary"
          ) {{ uploadValue.toFixed() + '%' }}
        .text-end
          v-btn(v-if="uploadValue == 100" color="success", @click="createAnnImg()" :loading="loading2") Add
          v-btn(v-else color="success" disabled) Add

      h3.mt-7 Image Announcements:
      v-carousel.mt-4(
        v-model="slide",
        height="auto"
        cycle
        progress
        progress-color="primary"
        hide-delimiters
        interval = "10000"
      )
        v-carousel-item(
          v-for="item in annImages",
          :key="item.id",
        ).text-center
          v-img(:src="item.image" aspect-ratio="2.82")
          v-btn(
            color="error",
            medium
            @click="deleteAnnImg(item.id, item.image)" :loading="loading3"
          ).mt-3.mb-4 DELETE {{(item.image.split('2F')[1]).split('?')[0]}}
    
  //- br
  //- v-card.pa-5.overflow-hidden(outlined)
  //-   .covered.ma-n5.pt-4.pb-2
  //-     h5.text-center.white--text Guidelines
  //-   .mt-3
  //-     textarea.form-control(rows="12")
  //- br
  //- v-card.pa-5.overflow-hidden(outlined)
  //-   .covered.ma-n5.pt-4.pb-2
  //-     h5.text-center.white--text Survey Form
  //-   v-card.mt-3(flat)
  //-     v-card-text.form-control Edit the content of the survey form in this link: https://knsndnws.com
  br
  br
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import { db } from "../main";
let createAnn = firebase.functions().httpsCallable("createAnn");
let createAnnImg = firebase.functions().httpsCallable("createAnnImg");
let getAnns = firebase.functions().httpsCallable("getAnns");
let deleteAnn = firebase.functions().httpsCallable("deleteAnn");
let deleteAnnImg = firebase.functions().httpsCallable("deleteAnnImg");

export default {
  data() {
    return {
      name: "",
      description: "",
      anns: [],
      loading: false,
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      imageData: null,
      picture: null,
      uploadValue: 0,
      image: "",
      annImages: [],
      slide: 0,
    };
  },

  async created() {
    this.anns = (await getAnns()).data.announcements;
    this.flashMessage.setStrategy("multiple");
  },

  mounted() {
    this.$bind("annImages", db.collection("annImages").orderBy("image"));
  },

  methods: {
    submit() {
      this.$v.$touch();
    },
    async createAnn() {
      this.loading = true;
      await createAnn({
        name: this.name,
        description: this.description,
      });
      this.flashMessage.success({
        title: "Text announcement was added successfully",
        message: "You successfully added a text announcement!",
        icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
      });
      this.anns = (await getAnns()).data.announcements;
      this.loading = false;
      this.name = "";
      this.description = "";
    },

    async createAnnImg() {
      await createAnnImg({
        image: this.picture,
      });
      this.flashMessage.success({
        title: "Image announcement was added successfully",
        message: "You successfully added an image announcement!",
        icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
      });
      this.picture = null;
      this.uploadValue = 0;
      this.imageData = null;
    },

    async deleteAnn(id) {
      this.loading1 = true;
      await deleteAnn({ id });
      this.flashMessage.error({
        title: "Text announcement was deleted successfully",
        message: "You successfully deleted a text announcement!",
        icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg1IO_-pku4M8rgrRdWC4CFAyK_jH_QLcOGA&usqp=CAU",
      });
      this.anns = (await getAnns()).data.announcements;
      this.loading1 = false;
    },

    async deleteAnnImg(id, image) {
      this.loading3 = true;
      await deleteAnnImg({ id });
      let imageRef = firebase.storage().refFromURL(image);
      imageRef.delete();
      this.loading3 = false;
      this.flashMessage.error({
        title: "Image announcement was deleted successfully",
        message: "You successfully deleted an image announcement!",
        icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg1IO_-pku4M8rgrRdWC4CFAyK_jH_QLcOGA&usqp=CAU",
      });
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    onUpload() {
      this.loading4 = true;
      this.picture = null;
      //create reference to storage and storage file
      const storageRef = firebase
        .storage()
        .ref(`images/${this.imageData.name}`) //ref is directory of file in console
        .put(this.imageData);
      //progress bar
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.picture = url;
          });
        }
      );
      this.loading4 = false;
    },
  },
};
</script>

<style>
.s1 {
  object-fit: contain;
}
.covered {
  background: rgba(2, 0, 115, 0.8);
}
.v-card {
  z-index: 0 !important;
}
._vue-flash-msg-body {
  z-index: 99 !important;
}

.vue-flash-msg-body__content {
  padding: 5px !important;
  z-index: 99 !important;
}
._vue-flash-msg-body__title {
  font-weight: bold;
  margin-top: 9px;
  font-size: 20px;
  font-family: sans-serif;
  z-index: 99 !important;
}
._vue-flash-msg-body__text {
  margin-top: -15px;
  font-family: sans-serif;
  z-index: 99 !important;
}
</style>
